import Typography from "@mui/material/Typography";

type PageTitleProps = {
  children: React.ReactFragment;
};

export function PageTitle(props: PageTitleProps) {
  const { children } = props;
  return (
    <Typography component="h1" variant="h2" sx={{ my: 4 }}>
      {children}
    </Typography>
  );
}
