import { PageCenter } from "@/lib/react/page-center/page-center";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { getCsrfToken } from "next-auth/react";
import { GetServerSidePropsContext } from "next";
import { PageTitle } from "@/lib/mui/PageTitle";
import { Paragraph } from "@/lib/mui/Paragraph";
import router from "next/router";
import { simpleArg } from "@/lib/next/simpleArg";

type PageProps = {
  csrfToken: string;
  callbackUrl: string | null;
};

export default function SignIn({ csrfToken, callbackUrl }: PageProps) {
  return (
    <PageCenter>
      <form method="post" action="/api/auth/signin/email">
        <input name="csrfToken" type="hidden" defaultValue={csrfToken} />
        {callbackUrl && (
          <input name="callbackUrl" type="hidden" value={callbackUrl} />
        )}

        <PageTitle>Login</PageTitle>

        <Paragraph>
          Enter your email and you we will send you a link to sign in with.
        </Paragraph>

        <Paragraph>
          If you do not have an account yet, one will be created for you.
        </Paragraph>

        <Box mb={2}>
          <TextField
            fullWidth
            type="email"
            autoFocus
            id="email"
            name="email"
            label="Email"
            variant="outlined"
          />
        </Box>

        <Button type="submit" variant="contained">
          Log in with Email
        </Button>
      </form>
    </PageCenter>
  );
}

export async function getServerSideProps(context: GetServerSidePropsContext) {
  const csrfToken = await getCsrfToken(context);
  if (!csrfToken) {
    throw new Error("CSRF token not found");
  }

  const callbackUrl = simpleArg(context.query.callbackUrl);

  return {
    props: { csrfToken, callbackUrl } satisfies PageProps,
  };
}
